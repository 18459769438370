import React from 'react';

import { API } from '@stoplight/elements';
import '@stoplight/elements/styles.min.css';


function App() {
  return (
    <div className="App">
      <API
        apiDescriptionUrl="/swagger_doc.json"
        router="hash"
      />
    </div>
  );
}

export default App;
